.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 50px;
    width: 100%;
}

.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 65px;
    padding-top: 70px;
}

.logo-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.logo-spaced {
    margin-top: 40px;
}

.main-content {
    padding-top: 20px;
}

body {
    background-color: #111;
    color: rgba(0, 255, 0, 0.9);
    font-family: 'Courier New', Courier, monospace;
}

a {
    color: rgba(0, 255, 0, 0.9);
    text-decoration: none;
}

.Thread a {
    color: #66ccff;
    text-decoration: none;
}

.Thread a:hover {
    text-decoration: underline;
}

button {
    background-color: #222;
    border: 1px solid rgba(0, 255, 0, 0.8);
    border-radius: 4px;
    color: rgba(0, 255, 0, 0.9);
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1rem;
    padding: 8px 16px;
}

button:hover {
    background-color: #333;
}

input,
textarea {
    background-color: #222;
    border: 1px solid rgba(0, 255, 0, 0.8);
    border-radius: 4px;
    color: rgba(0, 255, 0, 0.9);
    font-family: 'Courier New', Courier, monospace;
    font-size: 1rem;
}

.whitetext {
    color: whitesmoke;
    font-style: italic;
    font-weight: 100;
}


.App {
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
}

.ThreadIndex {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 25px;
    justify-content: center;
    align-items: stretch;
    padding: 25px 0;
}

.ThreadCardInfo {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    margin-top: -12px;
    max-width: 99%;
}

.ThreadCardComment {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
}

.ReplyCount {
    margin-bottom: 0.5rem;
}

.ThreadCard {
    align-items: center;
    border: 1px solid rgba(0, 255, 0, .8);
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 300px;
    min-height: 200px;
    padding: 16px;
    position: relative;
    text-align: center;
    width: 100%;
    overflow: hidden;
}

.ThreadCard:hover {
    background-color: #222;
}

.ThreadCard h3 {
    font-size: 1.4rem;
    margin-bottom: -4px;
    margin-top: -5px;
}

.ThreadCard .ThreadIndexThumbnail {
    max-height: 40%;
    object-fit: cover;
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-top: 10px;
}

.ThreadCard .ThreadIndexThumbnail img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

@media (max-width: 767px) {
    .ThreadCard {
        height: auto;
        padding: 8px;
    }

    .ThreadIndexThumbnail {
        height: 150px;
    }
}

.ThreadCard p {
    margin-bottom: 6px;
    margin-top: 1px;
    max-height: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ThreadCard:hover {
    background-color: #222;
}

.Thread {
    max-width: 800px;
    margin: 0 auto;
    padding: 1rem;
}

.interest-title {
    text-align: center;
    margin-bottom: 0px;
    margin-top: 93px;
}

.ThreadIndexTopButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4px;
    margin-bottom: 8px;
}

.ThreadIndexTopButtons button,
.ThreadIndexTopButtons a button {
    margin-bottom: 4px;
}

.search-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
    margin-left: 21px;
    margin-top: 25px;
}

.search-input {
    width: 100%;
    max-width: 300px;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

@media (min-width: 768px) {
    .ThreadIndexTopButtons {
        justify-content: space-between;
    }

    .ThreadIndexTopButtons button,
    .ThreadIndexTopButtons a button {
        height: 34px;
        line-height: 0px;
        margin-bottom: 0;
    }
}

.TopButtonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.TopButtons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    max-width: 100%;
    padding: 0px 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.TopButtons button {
    height: 34px;
    width: 150px;
}

.BottomButtons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.top-button-container {
    position: relative;
    height: 0;
    width: 100%;
}

.top-button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
    .TopButtons {
        grid-template-columns: repeat(2, 1fr);
    }

    .TopButtons button {
        height: 34px;
        line-height: 0px;
        width: 150px;
        font-size: 0.8rem;
    }
}

.Posts {
    margin-bottom: 1rem;
}

button.reply-button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.8rem;
    padding: 4px 8px;
}

.Post {
    position: relative;
    border: 1px solid #ccc;
    padding: 1rem;
    margin-bottom: 1rem;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
}

.HighlightedPost {
    background-color: rgba(0, 191, 255, 0.2);
    border: 1px solid rgba(0, 191, 255, 0.4);
}

.voting-and-hbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hbar-amount-container {
    font-weight: bold;
    margin-left: 10px;
    color: rgba(0, 255, 0, 0.9);
    font-family: 'Courier New', Courier, monospace;
}

.voting-container {
    display: inline-block;
    text-align: center;
}

.vote-container {
    display: flex;
    justify-content: space-between;
    width: 50px;
}

.vote-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.amount-input-container {
    display: flex;
    align-items: center;
    margin-top: 4px;
}

.vote-button {
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.vote-count {
    display: block;
    font-size: 0.9rem;
}

.amount-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.input-button-wrapper {
    display: flex;
    align-items: stretch;
    border: 1px solid rgba(0, 255, 0, 0.8);
    border-radius: 4px;
    overflow: hidden;
}

.amount-input {
    background-color: #222;
    color: rgba(0, 255, 0, 0.9);
    font-family: 'Courier New', Courier, monospace;
    font-size: 1rem;
    padding: 4px 8px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: -1px;
    border: none;
    border-right: 1px solid rgba(0, 255, 0, 0.8);
}

.send-icon {
    background-color: #222;
    color: rgba(0, 255, 0, 0.9);
    padding: 4px 8px;
    font-size: 1rem;
    border: none;
    cursor: pointer;
}

.send-icon:hover {
    color: #007bff;
}


.Post.Anchor {
    background-color: #222;
}

.Vote {
    display: flex;
    flex-direction: column;
}

.PostId {
    font-size: 0.8rem;
}

.ReplyIds {
    font-size: 0.8rem;
}

.reply-link {
    cursor: pointer;
    margin-right: 5px;
}

.NewThread {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.ImageContainer {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 12px;
    cursor: pointer;
    width: 120px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid rgba(0, 255, 0, 0.6);
}

.ThreadIndexThumbnail {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}

.ImageContainer:hover {
    border-color: rgba(0, 255, 0, 0.8);
}

.ExpandedImage {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 8px;
    border-radius: 4px;
    border: 1px solid rgba(0, 255, 0, 0.6);
}

@keyframes glowing {
    0% {
        box-shadow: 0 0 5px rgba(0, 255, 0, 0.5), 0 0 10px rgba(0, 255, 0, 0.5);
    }

    50% {
        box-shadow: 0 0 20px rgba(0, 255, 0, 1), 0 0 30px rgba(0, 255, 0, 1);
    }

    100% {
        box-shadow: 0 0 5px rgba(0, 255, 0, 0.5), 0 0 10px rgba(0, 255, 0, 0.5);
    }
}

.hashconnect-button {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: 4px;
}

.hashconnect-button:not(.connected) {
    animation: glowing 2s infinite;
}

.connected-account-id {
    color: #00ff00;
    font-family: "Courier New", Courier, monospace;
    font-size: 18px;
    font-weight: bold;
    margin-left: 5px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.connected-dot {
    background-color: #7DF9FF;
    ;
    border-radius: 50%;
    display: inline-block;
    height: 7px;
    width: 7px;
    box-shadow: 0 0 10px #0f0;
    animation: glowing 2s infinite;
}

.hashconnect-button.connected button {
    background-color: transparent;
    animation: none;
    text-shadow: none;
}

.hashconnect-button .disconnect-button {
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-family: "Courier New", Courier, monospace;
    font-size: 14px;
    padding: 5px 10px;
    background-color: rgba(255, 0, 0, 0.2);
    text-shadow: 0 0 5px #f00, 0 0 10px #f00, 0 0 20px #f00, 0 0 30px #f00, 0 0 40px #f00;
    transition: background-color 0.3s ease;
}

.hashconnect-button .disconnect-button:hover {
    background-color: rgba(255, 0, 0, 0.4);
}

.hashconnect-button .connect-button {
    border: none;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    font-family: "Courier New", Courier, monospace;
    font-size: 14px;
    padding: 5px 10px;
    background-color: rgba(0, 255, 0, 0.2);
    text-shadow: 0 0 5px #0f0, 0 0 10px #0f0, 0 0 20px #0f0, 0 0 30px #0f0, 0 0 40px #0f0;
    transition: background-color 0.3s ease;
}

.hashconnect-button .connect-button:hover {
    background-color: rgba(0, 255, 0, 0.4);
}

.about-link {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 0.9rem;
}

.header-dropdown-container {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    align-items: center;
    height: 30px;
}

.header-dropdown {
    background-color: #222;
    border: 1px solid rgba(0, 255, 0, 0.8);
    border-radius: 4px;
    color: rgba(0, 255, 0, 0.9);
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.8rem;
    padding: 0px;
    width: 200px;
    height: 18px;
    cursor: pointer;
}

.header-dropdown::-ms-expand {
    display: none;
}

.header-dropdown::after {
    content: "▼";
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    font-size: 0.8em;
    padding-left: 2px;
    color: rgba(0, 255, 0, 0.9);
    pointer-events: none;
}

label {
    margin-bottom: 10px;
}

.newthread-dropdown-container {
    align-self: stretch;
}

.newthread-dropdown {
    background-color: #222;
    border: 1px solid rgba(0, 255, 0, 0.8);
    border-radius: 4px;
    color: rgba(0, 255, 0, 0.9);
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.8rem;
    padding: 0px;
    width: 200px;
    height: 18px;
    cursor: pointer;
    margin-bottom: 20px;
}

.newthread-dropdown::-ms-expand {
    display: none;
}

.newthread-dropdown::after {
    content: "▼";
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    font-size: 0.8em;
    padding-left: 2px;
    color: rgba(0, 255, 0, 0.9);
    pointer-events: none;
}

@media screen and (max-width: 767px) {
    form {
        padding: 1rem;
        width: 90%;
    }
}

@media screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    form {
        padding: 1rem;
        width: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.filter-input-button-container {
    display: flex;
}

.filter-input-button-container input,
.filter-input-button-container button {
    height: 30px;
}

.filter-input-button-container button,
.close-help {
    font-size: 0.8rem;
    padding: 0 8px;
    line-height: 30px;
}

.help-icon,
.filter-icon,
.edit-icon,
.delete-icon,
.save-and-exit-icon {
    font-size: 0.8rem;
    margin: 0 2px;
    background: none;
    border: none;
    cursor: pointer;
    color: rgba(0, 255, 0, .9);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.filter-icon {
    font-size: 1.2rem;
}

.help-icon {
    font-size: 0.8rem;
}

.faucet-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.icon-container {
    display: flex;
    align-items: center;
}

.message-container {
    flex-grow: 1;
}

.faucet-icon {
    outline: none;
    position: absolute;
    top: 0;
    font-size: 1.4rem;
    margin: 0 2px;
    background: none;
    border: none;
    cursor: pointer;
    color: rgba(0, 255, 0, .9);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    z-index: 100;
}

.filter-icon {
    font-weight: 300;
}

.filter-icon:hover,
.help-icon:hover,
.edit-icon:hover,
.delete-icon:hover,
.faucet-icon:hover,
.save-and-exit-icon:hover {
    color: #007bff;
}

.add-filter {
    display: flex;
    flex-wrap: wrap;
}

.add-filter form {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    margin-bottom: 8px;
    padding-left: 0px;
}

.add-filter-input-container {
    flex-grow: 1;
    margin-right: 8px;
}

.add-filter-input-container input {
    width: 100%;
    height: 30px;
}

.add-filter-btn {
    height: 23px;
    font-size: 0.8rem;
    padding: 0 8px;
    line-height: 23px;
}

@media (max-width: 576px) {
    .add-filter-btn {
        font-size: 0.7rem;
        padding: 0 6px;
    }
}

@media (max-width: 576px) {
    .add-filter-input-container {
        padding-left: 0;
    }
}

@media (max-width: 576px) {

    .filter-input-button-container button,
    .close-help {
        font-size: 0.5rem;
        padding: 0 6px;
    }

    .help-icon,
    .edit-icon,
    .delete-icon {
        font-size: 0.7rem;
    }

    .filter-icon {
        font-size: 1rem;
    }

    .header-dropdown-container {
        padding-top: 25px;
    }
}

.save-and-exit-icon {
    border: none;
    background: none;
    height: 100%;
    padding: 0;
    margin-left: 10px;
    font-size: 1.25rem;
    cursor: pointer;
}

.error-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 30px;
    z-index: 200;
    margin: 1em 0;
    width: 100%;
}

.error,
.loading,
.success {
    color: red;
    font-weight: bold;
    width: 100%;
}

.success {
    color: rgba(0, 255, 0, .9);
}

.loading {
    color: #00ffff;
}

.centered-error {
    text-align: center;
}

.floating-reply-form {
    position: fixed;
    top: 50%;
    left: 32%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background: rgba(255, 255, 255, 0.5);
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    width: auto;
    box-sizing: border-box;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.exit-button {
    position: absolute;
    top: 0rem;
    right: 0rem;
    background: none;
    border: none;
    font-size: 1.35rem;
    cursor: pointer;
    font-weight: bold;
    color: inherit;
    text-decoration: none;
    padding: 0;
    margin: 0;
}

.floating-reply-textarea {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.floating-image-input {
    display: block;
    margin: 0 auto;
    background-color: transparent;
    border-color: transparent;
    color: black;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.submit-button {
    display: block;
    margin: 1rem auto;
}

@media (max-width: 768px) {
    .floating-reply-form {
        left: 5%;
    }
}