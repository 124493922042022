.NewThread {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 1rem;
}

.NewThread h2 {
    margin-bottom: 1rem;
}

.NewThread form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.NewThread label {
    margin: 0.5rem 0;
}

.NewThread input,
.NewThread textarea {
    width: 50%;
    margin-bottom: 1rem;
}

.NewThread textarea {
    height: 200px;
}


.NewThread button {
    margin-top: 1rem;
}

.BackButton {
    margin-bottom: 1rem;
    align-self: flex-start;
}

.subject-error {
    color: red;
    font-weight: bold;
}

@media (max-width: 767px) {

    #subject,
    #comment,
    #image {
        width: 100%;
    }
}